import {  notification } from 'antd';

export const SuccessNotification = (message) => {
    notification.success({
      message:`${message}`,
      placement:"topRight",
    });
  };

  export const ErrorNotification = (message) => {
    notification.error({
      message:`${message}`,
      placement:"topRight",
    });
  };

