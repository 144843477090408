import React from 'react'
import { Spin} from 'antd';
const Loading = () => {
    return (
        <div className='container-load' >
            <div className='container-child' >
            <Spin style={{display:'block'}}  tip="Loading..."/>
            </div>
        </div>
    )
}

export default Loading
