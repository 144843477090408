import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios'
import {BrowserRouter} from 'react-router-dom'


axios.defaults.baseURL = "https://insuranceyogi.online/api";
axios.defaults.withCredentials = true



ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);


