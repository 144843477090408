import React from "react";
import Router from "./Router";
import GlobalState from "./Components/GlobalState";

const App = () => {


  return (
    <GlobalState>
     <Router/>
    </GlobalState>
  );
};

export default App;
