import React, { useContext, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import Loading from "./Components/Extra/Loading";
import { ContextProvider } from "./Components/GlobalState";
import NavBar from "./Components/NavBar";

const SignInPage = React.lazy(() => import("./Components/SignInPage"));
const CreatePolicy = React.lazy(() => import("./Components/CreatePolicy"));
const TablePolicy = React.lazy(() => import("./Components/TablePolicy"));
const ChangePassword = React.lazy(() => import("./Components/ChangePassword"));
const Createuser = React.lazy(() => import("./Components/Createuser"));
const EditPolicy = React.lazy(() => import("./Components/EditPolicy"));
const TypeOfPolicy = React.lazy(() => import("./Components/TypeOfPolicy"));
const Notification = React.lazy(() => import("./Components/Notification"));
const Reports = React.lazy(() => import("./Components/Reports"));

const Router = () => {
  const context = useContext(ContextProvider);
  return (
    <>
      <Suspense fallback={Loading}>
        {context.auth ? (
          <span>
            <NavBar />
            <Switch>
              <Route exact path="/home" component={CreatePolicy} />
              <Route exact path="/viewpolicy" component={TablePolicy} />
              <Route exact path="/changepassword" component={ChangePassword} />
              <Route exact path="/notification" component={Notification} />
              <Route exact path="/editpolicy/:id" component={EditPolicy} />
              <Route exact path="/reports" component={Reports} />
              <Route exact path="/" component={CreatePolicy} />
              {context.adminAuth ? (
                <>
                  <Route exact path="/createuser" component={Createuser} />
                  <Route
                    exact
                    path="/createtypeofpolicy"
                    component={TypeOfPolicy}
                  />
                </>
              ) : null}
            </Switch>
          </span>
        ) : (
          <Switch>
            <Route exact path="/" component={SignInPage} />
          </Switch>
        )}
      </Suspense>
    </>
  );
};

export default Router;
