import React, { useContext, useEffect, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { NavLink } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import { ContextProvider } from "./GlobalState";
import {
  HiUserAdd,
  HiOutlineBell,
  HiOutlineLockClosed,
  HiOutlinePencil,
  HiOutlineViewGrid,
  HiOutlineLogout,
  HiOutlinePencilAlt,
  HiOutlineUserCircle,
  HiOutlineTerminal,
  HiOutlinePresentationChartBar,
} from "react-icons/hi";
import axios from "axios";
import { SuccessNotification } from "./Extra/NotificationCall";
import { NavDropdown } from "react-bootstrap";

const NavBar = () => {
  const [alertData, setAlertData] = useState();
  useEffect(() => {
    axios.get("/getalert").then((res) => {
      if (res.data.length > 0) {
        setAlertData(res.data.length);
        SuccessNotification(
          `${res.data.length} Policy will expire after 20 days `
        );
      }
    });
  }, []);
  const context = useContext(ContextProvider);
  return (
    <div>
      <Navbar bg="dark" expand="lg">
        <Navbar.Brand style={{ color: "white", paddingLeft: "10px" }}>
          Hello,
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto mr-4">
            <NavLink className="nav-link" to="/home">
              <HiOutlinePencil style={{ marginBottom: "5px" }} /> Create Policy
            </NavLink>
            <NavLink className="nav-link" to="/reports">
              <HiOutlinePresentationChartBar style={{ marginBottom: "5px" }} />{" "}
              Reports
            </NavLink>
            <NavLink className="nav-link" to="/viewpolicy">
              <HiOutlineViewGrid style={{ marginBottom: "5px" }} /> View Policy
            </NavLink>
            <NavDropdown
              style={{ color: "black" }}
              title={
                <>
                  <HiOutlineUserCircle style={{ marginBottom: "5px" }} />{" "}
                  Profile{" "}
                  <Badge pill variant="danger">
                    {alertData}
                  </Badge>
                </>
              }
              id="basic-nav-dropdown"
            >
              <NavLink className="nav-link" to="/changepassword">
                <HiOutlineLockClosed style={{ marginBottom: "5px" }} /> Change
                Password
              </NavLink>
              <NavLink className="nav-link" to="/notification">
                <HiOutlineBell style={{ marginBottom: "5px" }} /> Notification
                <Badge pill variant="danger">
                  {alertData}
                </Badge>
              </NavLink>
              <NavLink
                className="nav-link"
                onClick={context.logout}
                to="/logout"
              >
                <HiOutlineLogout style={{ marginBottom: "5px" }} /> Logout
              </NavLink>
            </NavDropdown>

            {context.adminAuth ? (
              <NavDropdown
                style={{ color: "black" }}
                title={
                  <>
                    <HiOutlineTerminal style={{ marginBottom: "5px" }} /> Admin
                  </>
                }
                id="basic-nav-dropdown"
              >
                <NavLink className="nav-link" to="/createuser">
                  <HiUserAdd style={{ marginBottom: "5px" }} /> Create User
                </NavLink>
                <NavLink className="nav-link" to="/createtypeofpolicy">
                  <HiOutlinePencilAlt style={{ marginBottom: "5px" }} /> Type of
                  Policy
                </NavLink>
              </NavDropdown>
            ) : null}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default NavBar;
