import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import cookie from 'react-cookies'
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
export const ContextProvider = React.createContext();

const { confirm } = Modal;


const GlobalState = (props) => {
  const [auth, setAuth] = useState(false);
  const [adminAuth, setAdminAuth] = useState(false);
  const history = useHistory();
 
  const checkToken = () => {
      axios.get("/getin").then((res) => {
        setAuth(res.data.x);
        setAdminAuth(res.data.y);
      //   setTimeout((()=>{
      //     history.push('/home')
      // }),200)
      }).catch(err=>{
        if(err.response.status === 401){
          setAdminAuth(false)
          setAuth(false)
          cookie.remove('token', { path: '/' })
          cookie.remove('Email',{ path: '/' })
          setTimeout((()=>{
            history.push('/')
        }),200)
        }
      })
    }
  
const logout = () =>{
  confirm({
    title: 'Do you want to Logout?',
    icon: <ExclamationCircleOutlined />,
    content: 'Press *Cancel to go back',
    onOk() {
      axios.get('/logout').then(res => {
        if(res.status === 200){
            setAdminAuth(false)
            setAuth(false)
            setTimeout((()=>{
                history.push('/')
            }),200)
        }
    }).catch(err=>{
        setAdminAuth(false)
        setAuth(false)
        cookie.remove('token', { path: '/' })
        cookie.remove('Email',{ path: '/' })
        setTimeout((()=>{
          history.push('/')
      }),200)
    })
    },
    onCancel() {
      history.goBack()
    },
  });
}


  useEffect(() => {
    checkToken()
    // eslint-disable-next-line 
  }, []);

  return (
    <>
      <ContextProvider.Provider
        value={{
          auth: auth,
          setAuth: setAuth,
          setAdminAuth: setAdminAuth,
          adminAuth: adminAuth,
          checkToken: checkToken,
          logout:logout
        }}
      >
        {props.children}
      </ContextProvider.Provider>
    </>
  );
};

export default GlobalState;
